<template>
  <div class="new__table new__table_result" v-loading="loadingData">
    <div class="scheduler">
      <div class="schedule">
        <div class="crm-schedule-box d-inline-flex w-100">
          <table class="table table-bordered nurseOffice_table crm-schedule-table table-bordered">
            <thead>
              <tr>
                <th>{{ $t('message.drug') }}</th>
                <th v-for="date in dateList" :key="date.val + '-datess'">
                  {{ date.title }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="patientIndicator in list" :key="patientIndicator.id">
                <td class="left_likarstovas">
                  <div class="modalCells">
                      {{  patientIndicator.patientIndicatorName ?  patientIndicator.patientIndicatorName.name : '' }}
                  </div>
                  <div class="ta_button">
                      <el-button type="success" v-can="'dailyTreatments.update'"  icon="el-icon-edit" @click="edit(patientIndicator)">{{ $t('message.change') }}</el-button>
                      <el-button type="danger"  v-can="'dailyTreatments.delete'" icon="el-icon-delete" @click="destroyModel(patientIndicator)">{{ $t('message.delete') }}</el-button>
                  </div>
                </td>
                  
                <td v-for="date in dateList" :key="date.val + '-dat  '" class="dataDay">
                  <template v-if="hasLek(date,patientIndicator)">
                    <template v-if="patientIndicator.is_edit == 1">
                        <template v-for="i in patientIndicator.count" >
                            <div class="modalCells is_edit" :class="'height'+patientIndicator.count" :key="i + '-count'" @click="openPatient(date, patientIndicator, i)">{{ showPatient(date, patientIndicator, i) }}</div>
                        </template>
                    </template>
                    <template  v-else>
                        <template v-for="i in patientIndicator.count" >
                            <div class="modalCells" :class="'height'+patientIndicator.count" :key="i + '-count'" @click="openPatient(date, patientIndicator, i)">{{ showPatient(date, patientIndicator, i) }}</div>
                        </template>
                    </template>
                  </template>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <el-dialog
        class="modal_lea xModal"
        title="Состояние пациента"
        width="30%"
        :visible.sync="dialogTableVisible2"
        @closed="closeDialog()"  @opened="$refs['addResult'].afterOpened()"
      >
      <AddResult :selected="selectedModel" ref="addResult" @c-close="closeDialog" />
      </el-dialog>
    </div>
      <el-drawer 
          size="100%"  :wrapperClosable="false"
          :visible.sync="drawer.update.status"
          :ref="drawer.update.name"
          @opened="drawerOpened(drawer.update.component)"
          @closed="drawerClosedAny(drawer.update.component)">
              <crm-update :selected="selectedModel" :ref="drawer.update.component" :drawer-name="drawer.update.name"></crm-update>
      </el-drawer>
  </div>
</template>
<script>
  import AddResult from "./components/addResult";
  import drawer from "@/utils/mixins/drawer";
  import CrmUpdate from "./components/updateMedicl";
  import { i18n } from '@/utils/i18n';

  import {
    mapActions,
    mapGetters,
  } from 'vuex';
  import list from "@/utils/mixins/list";
  export default {
    mixins: [list, drawer],
    // props: ['selected'],
    name: "patientHome",
    components: {
      AddResult,
      CrmUpdate
    },
    data() {
      return {
        dialogTableVisible2: false,
        loadingData: false,
        selectedModel: {},
        filterForm: {},
        drawer: {
          update: {
            name: "update",
            status: false,
            component: 'componentDrawerUpdate'
          }
        }
      }
    },
    computed: {
      getId() {
        return this.$route.params.id;
      },
      ...mapGetters({
        indexList: 'patientIndicatorIndeces/list',
        list: 'patientIndicators/list',
        columns: "patientIndicators/columns",
        dateList: 'patientIndicators/dateList',
        pagination: "patientIndicators/pagination",
        filter: "patientIndicators/filter",
        sort: "patientIndicators/sort",
        lastHistory: 'dailyTreatments/lastHistory',

      }),
      actions: function () {
        return ['edit', 'delete'];
      }
    },
    async mounted() {
      $(".new__table_result .crm-schedule-table tbody").scroll(function (e) {
        //detect a scroll event on the tbody
        $(".new__table_result .crm-schedule-table thead").css(
          "left",
          -$(".new__table_result .crm-schedule-table tbody").scrollLeft()
        ); //fix the thead relative to the body scrolling
        $(".new__table_result .crm-schedule-table thead th:nth-child(1)").css(
          "left",
          $(".new__table_result .crm-schedule-table tbody").scrollLeft()
        ); //fix the first cell of the header
        $(".new__table_result .crm-schedule-table tbody td:nth-child(1)").css(
          "left",
          $(".new__table_result .crm-schedule-table tbody").scrollLeft()
        ); //fix the first column of tdbody
      });
    },
    methods: {
      ...mapActions({
        updateList: 'patientIndicators/index',
        updateSort: "patientIndicators/updateSort",
        updateFilter: "patientIndicators/updateFilter",
        updateColumn: "patientIndicators/updateColumn",
        updatePagination: "patientIndicators/updatePagination",
        editModel: 'patientIndicators/show',
        empty: 'patientIndicators/empty',
        delete: 'patientIndicators/destroy',
        refreshData: 'patientIndicators/refreshData',
      }),
      hasLek(date, dailyT) {
        if (dailyT.end_date) {
          let days = dailyT.end_date;
          let parts = date.val.split("/");
          let year = parts[0];
          let month = parts[1];
          let day = parts[2];
          let newDateString = day + "." + month + "." + year;
          if (_.isArray(days) && days.includes(newDateString)) {
            return true;
          }
        }
      },
      drawerClosedAny(ref) {
          this.$refs[ref].closed();
          if (this.reloadList === true) {
            this.fetchData();
            this.fetchDataAny();
            this.afterFetchData();
          }
      },  
      fetchData(){

      },
      fetchDataAny() {
        this.loadingData = true;
        this.updateList({hospital_patient_id: this.getId, id: this.lastHistory.id})
            .then(res => {
                this.loadingData = false;            
            }).catch(err => {
                this.loadingData = false;
                this.$notify({
            title: "Ошибка",
            type: "error",
            offset: 130,
            message: err.error.message,
          })
            });
      },
      classObject: function (date,dt,i) {  
          let a = this.checkIndex(date, dt, i);
          return [{ 
            'on_lics': (a == 'plus'),
            'od_lics': (a == 'minus'), 
            'off_lics' : (a == 'defect')
          },'height' + dt.count];          
      },
      refresh() {
        this.refreshData()
          .then(res => {
            this.filterForm = JSON.parse(JSON.stringify(this.filter));
          })
          .catch(err => {

          })
      },
      openPatient(date,dt,i){
        if (dt.patientIndicatorIndeces) {
          
          let days = dt.patientIndicatorIndeces;
            
              if (_.isArray(days)) {
                  let item = _.find(days, 
                  function (o) {  return (o.day == date.val && o.time == i)});
                  // console.log(item);
                  console.log(item.comment);
                  if (item) {
                      this.dialogTableVisible2 = true;
                      this.selectedModel = item;
                  }     
              }
          }
      },
      showPatient(date,dt,i){
        if (dt.patientIndicatorIndeces) {
          
          let days = dt.patientIndicatorIndeces;
            
              if (_.isArray(days)) {
                  let item = _.find(days, 
                  function (o) {  return (o.day == date.val && o.time == i)});
                  if (item) {
                    return item.comment
                  }  
              }
          }
      },
      checkIndex(date, dt, i){
        if (dt.patientIndicatorIndeces) {
          let indeces = dt.patientIndicatorIndeces;
              if (_.isArray(indeces)) {
                  let item = _.find(indeces, function (o) { return (o.day == date.val && o.time == i) });
                  return item ? item.index : 'minus';
              }
        }
      },
      closeDialog(){
        this.dialogTableVisible2 = false
        this.fetchDataAny()
      },
      edit(model){
            this.selectedModel = model;
            this.drawer.update.status = true;
        },
      destroyModel(model){
          this.$confirm(
                   i18n.t('message.do_you_want'),
                     i18n.t('message.warning'), {
                        confirmButtonText: i18n.t('message.yes'),
                        cancelButtonText: i18n.t('message.cancel'),
                        type: "warning"
                    }
          )
          .then(() => {
              this.delete(model.id)
                  .then(res => {
                      this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
                      this.fetchData()
                  })
                  .catch(err => {
                      console.log(err)
                  })
          })
          .catch(() => {
              this.$message({
              type: "warning",
              message:  i18n.t('message.cancel')
              });
          });
      },
      emptyModel() {
        this.empty()
      },
    }
  };
</script>
<style lang="css">
.new__table .scheduler table.crm-schedule-table>tbody tr td:nth-child(1){
      flex-direction: column;
}
.modalCells{
    flex: 1;
}
.is_edit{
  background: #f56c6c8a !important
}
.ta_button{
 width: 100%;
 display: flex;
 justify-content: space-around;
 margin-bottom: 3px;
}
.ta_button .el-button{
    width: 48%;
    margin: 0px;
}
.new__table.new__table_2 tbody::-webkit-scrollbar {
  width: 10px;               /* width of the entire scrollbar */
}
.new__table.new__table_2 tbody::-webkit-scrollbar-track {
  background: #f1f1f1;        /* color of the tracking area */
}
.new__table.new__table_2 tbody::-webkit-scrollbar-thumb {
  background-color: #d2d2d2;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid #f1f1f1;  /* creates padding around scroll thumb */
}
body::-webkit-scrollbar {
  width: 15px;               /* width of the entire scrollbar */
}
body::-webkit-scrollbar-track {
  background: #fff;        /* color of the tracking area */
}
body::-webkit-scrollbar-thumb {
  background-color: #d2d2d2;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid #fff;  /* creates padding around scroll thumb */
}
</style>
