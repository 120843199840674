<template>
  <div>
    <div class="modal_header" v-loading="loadingData">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">Добавить лекарства</div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button type="success">{{$t('message.save_and_close')}}</el-button>
              <el-button>{{$t('message.close')}}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="center__block_form">
      <el-form ref="form" :model="form" class="aticler_m" size="small">
        <div class="add__likarstova">
          <el-row :gutter="20">
            <el-col :xs="12" :md="6" :lg="6">
              <div class="Add medicine">
                <div class="input_plusc">
                  <el-form-item label="Назначения лекарства ( склад )">
                    <el-select v-model="form.region1" placeholder=".....">
                      <el-option label="2" value="shanghai"></el-option>
                      <el-option label="3" value="beijing"></el-option>
                      <el-option label="4" value="beijing3"></el-option>
                    </el-select>
                  </el-form-item>
                  <i class="el-icon-plus"></i>
                </div>
                <!-- <el-form-item>
                        <el-select v-model="form.region2" placeholder=".....">
                          <el-option label="2" value="shanghai"></el-option>
                          <el-option label="3" value="beijing"></el-option>
                          <el-option label="4" value="beijing3"></el-option>
                        </el-select>
                </el-form-item>-->
              </div>
              <div class="Add medicine">
                <div class="input_plusc">
                  <el-form-item label="Назначения лекарства">
                    <el-input
                      v-model="form.name1"
                      placeholder="........."
                    ></el-input>
                  </el-form-item>
                  <i class="el-icon-plus"></i>
                </div>
                <!-- <el-form-item>
                        <el-input v-model="form.name1" placeholder="........."></el-input>
                </el-form-item>-->
              </div>
            </el-col>

            <!-- end col -->
            <el-col :xs="12" :md="6" :lg="6">
              <el-form-item label="Инструкция по применению" class="minheight">
                <el-input type="textarea" v-model="form.desc"></el-input>
              </el-form-item>
            </el-col>
            <!-- end col -->

            <el-col :xs="12" :md="6" :lg="6">
              <el-form-item label="Дата начала" class="minheight">
                <el-date-picker
                  type="date"
                  placeholder=".. / .. / ...."
                  v-model="form.date1"
                  style="width: 100%"
                  :format="'dd.MM.yyyy'"
                  :value-format="'dd.MM.yyyy'"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="Период">
                <el-date-picker
                  type="dates"
                  v-model="form.value4"
                  placeholder="Период"
                  :format="'dd.MM.yyyy'"
                  :value-format="'dd.MM.yyyy'"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <!-- end col -->
            <el-col :xs="12" :md="6" :lg="6">
              <el-form-item label="Количество назначений">
                <el-select v-model="form.multiple2" placeholder=".....">
                  <el-option label="1" value="beijing11"></el-option>
                  <el-option label="2" value="beijing22"></el-option>
                  <el-option label="3" value="beijing33"></el-option>
                  <el-option label="4" value="beijing44"></el-option>
                  <el-option label="5" value="beijing55"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- end col -->
          </el-row>
          <!-- end el-row -->
        </div>
      </el-form>
      <div class="add_pol">Добавить лекарства</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {},
      dialogImageUrl: "",
      dialogVisible: false,
      visible: false,
      visible2: false,
      visible3: false,
      visible4: false,
    };
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
  },
};
</script>
<style lang="scss">
.input_plusc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .el-icon-plus {
    border-radius: 5px;
    border: 1px solid #dcdfe6;
    height: 32px;
    line-height: 30px;
    width: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    color: #dcdfe6;
    cursor: pointer;
  }
}
.el-form-item {
  flex: 1;
}
.minheight .el-textarea__inner {
  min-height: 100px !important;
}
.add__likarstova {
  padding: 15px 15px 0px 15px;
  border: 1px solid #dcdfe6;
  border-radius: 5px;
  margin-bottom: 20px;
}
.add_pol {
  text-align: center;
  color: #3a90c3;
  font-size: 17px;
  cursor: pointer;
}
.indicators__table .el-checkbox__label {
  font-size: 14px !important;
  color: #303030 !important;
}
.indicators__table th {
  font-size: 16px !important;
  color: #303030 !important;
}
</style>